import { metaArrayToObject } from './index';

export function isSameCharity(charity1, charity2) {
  return (
    !!charity1.name &&
    charity1.name === charity2.name &&
    !!charity1.address &&
    charity1.address === charity2.address &&
    !!charity1.abn &&
    charity1.abn === charity2.abn
  );
}

export function localCharityData(userState, charityKey, referralCharities) {
  const hasRegionData =
    userState &&
    referralCharities[charityKey].regions &&
    referralCharities[charityKey].regions[userState];
  return hasRegionData
    ? { ...referralCharities[charityKey].regions[userState] }
    : { ...referralCharities[charityKey] };
}

export function charityToAnalyticsCharityParams(charity) {
  const charityMeta = metaArrayToObject(charity.meta);
  return {
    charity_name: charityMeta.name,
    featured_charity:
      !!charityMeta?.searchFeature || !!charityMeta?.suggestionSlot,
  };
}

export function giftsToGiftsAnalyticsParams(gifts) {
  return gifts.map((gift) => {
    const giftMeta = metaArrayToObject(gift.meta);
    const giftType = giftMeta.type;
    const giftValue = giftType === 'money' ? giftMeta.amount : null;
    const recipientType = gift.charity ? 'charity' : 'individual';
    let charityName = null;
    let featuredCharity = false;
    if (gift.charity) {
      const charityMeta = charityToAnalyticsCharityParams(gift.charity);
      charityName = charityMeta.charity_name;
      featuredCharity = charityMeta.featured_charity;
    }
    return {
      gift_type: giftType,
      gift_value: giftValue,
      recipient_type: recipientType,
      charity_name: charityName,
      featured_charity: featuredCharity,
    };
  });
}
