export const state = () => ({
  title: null,
  currentStep: 0,
  totalSteps: 99,
  modules: [
    {
      name: 'About Yourself',
      displayName: 'modules.will.aboutYourself.displayName',
      description: 'modules.will.aboutYourself.description',
      duration: [2, 4],
      totalSteps: 5,
      steps: ['name', 'date-of-birth', 'address', 'partner', 'phone'],
    },
    {
      name: 'Guardians',
      displayName: 'modules.will.guardians.displayName',
      description: 'modules.will.guardians.description',
      duration: [2],
      totalSteps: 6,
      steps: [
        'your-children',
        'primary',
        'backup',
        'your-pets',
        'pet-guardians',
        'pet-care-fund',
      ],
    },
    {
      name: 'Executors',
      displayName: 'modules.will.executors.displayName',
      description: 'modules.will.executors.description',
      duration: [4],
      totalSteps: 4,
      steps: ['', 'options', 'primary', 'backup'],
    },
    {
      name: 'Estate',
      displayName: 'modules.will.estate.displayName',
      description: 'modules.will.estate.description',
      duration: [4],
      totalSteps: 8,
      steps: [
        '',
        'beneficiaries',
        'distribution',
        'backup-beneficiaries',
        'backup-distribution',
      ],
    },
    {
      name: 'Gifts',
      displayName: 'modules.will.gifts.displayName',
      description: 'modules.will.gifts.description',
      duration: [1, 5],
      totalSteps: 2,
      steps: [''],
    },
    {
      name: 'Assets',
      displayName: 'modules.will.assets.displayName',
      description: 'modules.will.assets.description',
      duration: [2, 3],
      totalSteps: 4,
      steps: ['', 'asset-notes', 'liabilities', 'liability-notes'],
    },
    {
      name: 'Funeral',
      displayName: 'modules.will.funeral.displayName',
      description: 'modules.will.funeral.description',
      duration: [2, 3],
      totalSteps: 4,
      steps: ['', 'type', 'location', 'other-requests'],
    },
  ],
  poaModules: [
    {
      name: 'About Yourself',
      displayName: 'modules.poa.aboutYourself.displayName',
      description: 'modules.poa.aboutYourself.description',
      duration: [1, 2],
      totalSteps: 1,
      steps: [''],
    },
    {
      name: 'Financial',
      displayName: 'modules.poa.financial.displayName',
      description: 'modules.poa.financial.description',
      duration: [2, 5],
      totalSteps: 2,
      steps: ['', 'primary', 'substitutes', 'spending', 'limitations'],
    },
    {
      name: 'Medical',
      displayName: 'modules.poa.medical.displayName',
      description: 'modules.poa.medical.description',
      duration: [2, 5],
      totalSteps: 2,
      steps: ['', 'primary', 'substitutes', 'spending', 'limitations'],
    },
  ],
});

export const getters = {
  title: (state) => state.title,
  currentStep: (state) => state.currentStep,
  totalSteps: (state) => state.totalSteps,
  modules: (state) => state.modules,
  poaModules: (state) => state.poaModules,
};

export const mutations = {
  title(state, value) {
    state.title = value;
  },
  currentStep(state, value) {
    state.currentStep = value;
  },
  totalSteps(state, value) {
    state.totalSteps = value;
  },
};

export const actions = {
  setTitle({ commit }, value) {
    commit('title', value);
  },
  setCurrentStep({ commit }, value) {
    commit('currentStep', value);
  },
  setTotalSteps({ commit }, value) {
    commit('totalSteps', value);
  },
};
