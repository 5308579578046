export const state = () => ({
  logoVariant: 'safewill',
  navigationVariant: 'full',
  shouldShowCharityConsentPage: false,
  shouldShowCharityConsentModal: false,
});

export const getters = {
  logoVariant: (state) => state.logoVariant,
  navigationVariant: (state) => state.navigationVariant,
  shouldShowCharityConsentPage: (state) => state.shouldShowCharityConsentPage,
  shouldShowCharityConsentModal: (state) => state.shouldShowCharityConsentModal,
};

export const mutations = {
  logoVariant(state, value) {
    state.logoVariant = value;
  },
  navigationVariant(state, value) {
    state.navigationVariant = value;
  },
  shouldShowCharityConsentPage(state, value) {
    state.shouldShowCharityConsentPage = value;
  },
  shouldShowCharityConsentModal(state, value) {
    state.shouldShowCharityConsentModal = value;
  },
};

export const actions = {
  setLogoVariant({ commit }, value) {
    commit('logoVariant', value);
  },
  setNavigationVariant({ commit }, value) {
    commit('navigationVariant', value);
  },
  setShouldShowCharityConsent({ commit }, { page, modal }) {
    commit('shouldShowCharityConsentPage', page);
    commit('shouldShowCharityConsentModal', modal);
  },
  resetUI({ commit }) {
    commit('logoVariant', 'safewill');
    commit('navigationVariant', 'full');
  },
};
