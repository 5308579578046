import GET_PUBLIC_INVITE_QUERY from '@/graphql/queries/GetPublicInvite';

export default {
  async getPublicInviteCode({ state, commit, rootState }, hasFirstName) {
    if (!state.publicInviteCode && hasFirstName) {
      const {
        data: {
          getPublicInvite: {
            invite: { code },
          },
        },
      } = await this.app.apolloProvider.defaultClient.query({
        query: GET_PUBLIC_INVITE_QUERY,
        variables: {
          userId: rootState.userId,
        },
      });
      commit('setPublicInviteCode', code || null);
    }
  },
};
