/* eslint-disable camelcase */
import { configure, extend } from 'vee-validate';
import {
  email,
  required,
  max_value,
  min_value,
  integer,
} from 'vee-validate/dist/rules';
import { isBeneficiarySplitValid } from '@/utilities';

const setupValidation = () => {
  configure({
    useConstraintAttrs: false,
  });

  extend('integer', {
    ...integer,
    message: 'forms.errors.integer',
  });

  extend('email', {
    ...email,
    message: 'forms.errors.email',
  });

  extend('required', {
    ...required,
    message: 'forms.errors.required',
  });

  extend('max_value', {
    ...max_value,
    message(field, args) {
      return `forms.errors.max_value:${args.max}`;
    },
  });

  extend('min_value', {
    ...min_value,
    message(field, args) {
      return `forms.errors.min_value:${args.min}`;
    },
  });

  extend('phone', {
    message: 'forms.errors.phone',
    validate(value) {
      return new Promise((resolve) => {
        // https://stackoverflow.com/a/39990702/4605791

        const pattern =
          /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/gm;
        resolve({ valid: pattern.test(value) });
      });
    },
  });

  extend('date', {
    message: 'forms.errors.date',
    validate(value) {
      return new Promise((resolve) => {
        // https://stackoverflow.com/a/20773444/4605791

        const pattern =
          /(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/gm;
        resolve({ valid: pattern.test(value) });
      });
    },
  });

  extend('over18', {
    message: 'forms.errors.over18',
    validate(value) {
      return new Promise((resolve) => {
        const vals = value.split('/');
        const dob = new Date(vals[2], vals[1] - 1, vals[0]);
        const today = new Date();
        const birthday = new Date(
          today.getFullYear(),
          dob.getMonth(),
          dob.getDate()
        );
        const hadBirthday = today >= birthday;
        const years = today.getFullYear() - dob.getFullYear();
        const age = hadBirthday ? years : years - 1;
        resolve({ valid: age >= 18 });
      });
    },
  });

  extend('beneficiarySplit', {
    message: '',
    validate(value) {
      return new Promise((resolve) => {
        resolve({ valid: isBeneficiarySplitValid(value) });
      });
    },
  });
};

export default setupValidation;
