import { metaArrayToObject } from '../utilities';

const GET_BENEFICIARIES_QUERY = require('~/graphql/queries/GetBeneficiaries');
const GET_GIFTS_QUERY = require('~/graphql/queries/GetGifts');
const GET_WILL_QUERY = require('~/graphql/queries/GetWill');

async function getShouldShowCharityConsent(ctx) {
  const willId = ctx.store?.getters?.willId;
  const isMasquerading = ctx.store?.getters?.masquerading;
  if (!willId || isMasquerading) return false;

  const shouldShowCharityConsent = {
    modal: false,
    page: false,
  };

  const client = ctx.app.apolloProvider.defaultClient;
  const userIsConsumer =
    ctx.store.getters.role === 'CONSUMER' || ctx.store.getters.masquerading;

  let checkData = !!client && !!userIsConsumer && !!willId;

  const will = await client.query({
    query: GET_WILL_QUERY,
    fetchPolicy: 'no-cache',
    variables: {
      id: willId,
    },
  });
  const meta = checkData && will?.data?.getWill?.will?.meta;
  const userPreference = metaArrayToObject(meta).notify_charities;
  const userHasNotSetPreference =
    userPreference === undefined || userPreference === null;

  checkData = checkData && userHasNotSetPreference;

  if (checkData) {
    const {
      data: {
        getBeneficiaries: { beneficiaries },
      },
    } = await client.query({
      query: GET_BENEFICIARIES_QUERY,
      fetchPolicy: 'no-cache',
      variables: {
        willId,
      },
    });

    let userHasLeftSomethingToCharity =
      beneficiaries &&
      beneficiaries.some(
        (beneficiary) =>
          beneficiary.charity !== null ||
          beneficiary.backup?.some((backup) => backup.charity !== null)
      );

    if (!userHasLeftSomethingToCharity) {
      const {
        data: {
          getGifts: { gifts },
        },
      } = await client.query({
        query: GET_GIFTS_QUERY,
        fetchPolicy: 'no-cache',
        variables: {
          willId,
        },
      });

      userHasLeftSomethingToCharity =
        gifts && gifts.some((gift) => gift.charity !== null);
    }

    if (userHasLeftSomethingToCharity) {
      const userHasSubmittedWill = !['IN_PROGRESS', 'ARCHIVED'].includes(
        ctx.store.getters.willStatus
      );
      shouldShowCharityConsent.modal = userHasSubmittedWill;
      shouldShowCharityConsent.page = !userHasSubmittedWill;
    }
  }

  ctx.store.commit(
    'ruach/shouldShowCharityConsentPage',
    shouldShowCharityConsent.page
  );
  ctx.store.commit(
    'ruach/shouldShowCharityConsentModal',
    shouldShowCharityConsent.modal
  );
}

export default (ctx, inject) => {
  inject(
    'updateShouldShowCharityConsent',
    async () => await getShouldShowCharityConsent(ctx)
  );
  getShouldShowCharityConsent(ctx);
};
