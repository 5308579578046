import ADD_GUARDIAN_MUTATION from '@/graphql/mutations/AddGuardian';
import GET_GUARDIANS_QUERY from '@/graphql/queries/GetGuardians';
import REMOVE_GUARDIAN_MUTATION from '@/graphql/mutations/RemoveGuardian';

import { error } from '@/mixins/apollo';

export default {
  name: 'MixinsApolloGuardians',
  apollo: {
    guardians: {
      query: GET_GUARDIANS_QUERY,
      variables() {
        return {
          willId: this.willId,
        };
      },
      update: (data) => data.getGuardians && data.getGuardians.guardians,
      skip() {
        return !this.token || !this.willId;
      },
      error,
    },
  },
  data() {
    return {
      guardians: [],
    };
  },
  computed: {
    getGuardiansQuery() {
      return {
        query: GET_GUARDIANS_QUERY,
        variables: {
          willId: this.willId,
        },
      };
    },
    primaryGuardians() {
      return this.guardians.filter((guardian) => guardian.type === 'primary');
    },
    backupGuardians() {
      return this.guardians.filter((guardian) => guardian.type === 'backup');
    },
  },
  methods: {
    isGuardian(person) {
      return !!this.guardians.find(
        (guardian) => guardian.person.id === person.id
      );
    },
    isPrimaryGuardian(person) {
      return !!this.primaryGuardians.find(
        (guardian) => guardian.person.id === person.id
      );
    },
    isBackupGuardian(person) {
      return !!this.backupGuardians.find(
        (guardian) => guardian.person.id === person.id
      );
    },
    async addGuardian(person, type) {
      await this.$apollo.mutate({
        mutation: ADD_GUARDIAN_MUTATION,
        variables: {
          willId: this.willId,
          person: person.id,
          type,
        },
        update: (store, { data: { addGuardian } }) => {
          const data = store.readQuery(this.getGuardiansQuery);

          data.getGuardians.guardians.push(addGuardian.guardian);

          store.writeQuery({
            ...this.getGuardiansQuery,
            data,
          });
        },
      });
    },
    async removeGuardian(person) {
      const guardian = this.guardians.find(
        (guardian) => guardian.person.id === person.id
      );

      if (guardian) {
        await this.$apollo.mutate({
          mutation: REMOVE_GUARDIAN_MUTATION,
          variables: {
            id: guardian.id,
            willId: this.willId,
          },
          update: (store) => {
            const data = store.readQuery(this.getGuardiansQuery);
            const index = data.getGuardians.guardians.findIndex(
              (m) => m.id === guardian.id
            );

            if (index !== -1) {
              data.getGuardians.guardians.splice(index, 1);

              store.writeQuery({
                ...this.getGuardiansQuery,
                data,
              });
            }
          },
        });
      }
    },
    async removeAllGuardians() {
      await Promise.all(
        this.guardians.map((guardian) => {
          return this.removeGuardian(guardian.person);
        })
      );
    },
  },
};
