import GET_PURCHASED_PRODUCTS_QUERY from '@/graphql/queries/GetPurchasedProducts';

export default async function ({ redirect, store, app }) {
  const userId = store.getters.userId;
  const getPurchasedProducts = await app.apolloProvider.defaultClient.query({
    fetchPolicy: 'no-cache',
    query: GET_PURCHASED_PRODUCTS_QUERY,
    variables: {
      userId,
    },
  });
  const hasPurchasedWill =
    getPurchasedProducts?.data?.getPurchasedProducts?.products?.includes(
      'WILL'
    );
  if (!hasPurchasedWill) {
    return redirect('/');
  }
}
