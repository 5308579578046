import { mapGetters } from 'vuex';

import { getUserFullName } from '../utilities';
import { user, will } from '@/mixins/apollo';

export default {
  name: 'MixinsTracking',
  mixins: [user, will],
  computed: {
    ...mapGetters(['email', 'masquerading']),
  },
  watch: {
    email() {
      this.identifyUser();
    },
    userId() {
      this.identifyUser();
    },
  },
  mounted() {
    this.identifyUser();

    this.$nuxt.$on('sendTrackingAttributes', (traits) => {
      const name = getUserFullName(this.willMeta);

      const mergedTraits = Object.assign(traits, {
        name,
      });

      if (!this.masquerading && this.userId) {
        this.$analytics.identify(this.userId, mergedTraits);
      } else {
        // Segment handles Anonymous ID's
        this.$analytics.identify(mergedTraits);
      }
    });

    this.$nuxt.$on(
      'sendTrackingEvent',
      ({ event, props: sentProps, integrations = {} }) => {
        const props = {
          ...sentProps,
          foo: 'bar', // Zendesk requires that all events have at least one property,
          // so we need to add a dummy one here for them to work.
        };
        if (props && Object.keys(integrations).length) {
          this.$analytics.track(event, props, { integrations });
        } else {
          this.$analytics.track(event, props);
        }
      }
    );
  },
  beforeDestroy() {
    this.$nuxt.$off('sendTrackingAttributes');
    this.$nuxt.$off('sendTrackingEvent');
  },
  methods: {
    identifyUser() {
      const data = {};
      if (this.willMeta) {
        data.email = this.email;
        data.branch = process.env.BRANCH;
        data.release = process.env.COMMIT_REF;
        data.name = getUserFullName(this.willMeta);
      }

      if (this.userId) {
        this.$analytics.identify(this.userId, data);
      } else {
        this.$analytics.identify(data);
      }
    },
  },
};
