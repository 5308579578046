import Vue from 'vue';
import set from 'lodash/set';
import get from 'lodash/get';
import unset from 'lodash/unset';
import merge from 'lodash/merge';
import CREATE_END_OF_LIFE_ITEM_MUTATION from '@/graphql/mutations/CreateEndOfLifeItem';
import UPDATE_END_OF_LIFE_ITEM_MUTATION from '@/graphql/mutations/UpdateEndOfLifeItem';

let $apollo;
let $vault;

export class SensitiveModel {
  resourceCollection;
  privacyTargetCollection;
  createMutation;
  updateMutation;
  jsonMapping;
  metaMapping;

  constructor({
    resourceCollection,
    creationResolver = (data) => Object.values(data)[0],
    privacyTargetCollection = resourceCollection,
    createMutation,
    updateMutation,
    jsonMapping,
    metaMapping = [],
  }) {
    this.resourceCollection = resourceCollection;
    this.creationResolver = creationResolver;
    this.privacyTargetCollection = privacyTargetCollection;
    this.createMutation = createMutation;
    this.updateMutation = updateMutation;
    this.jsonMapping = jsonMapping;
    this.metaMapping = metaMapping;
  }

  _mapMetaToObject(data) {
    const mappedData = merge({}, data);
    this.metaMapping.forEach((key) => {
      set(
        mappedData,
        key,
        Object.fromEntries(get(data, key).map(({ key, value }) => [key, value]))
      );
    });
    return mappedData;
  }

  _mapObjectToMeta(metaData) {
    const mappedData = merge({}, metaData);
    this.metaMapping.forEach((metaKey) => {
      set(
        mappedData,
        metaKey,
        Object.entries(get(metaData, metaKey)).map(([key, value]) => ({
          key,
          value,
        }))
      );
    });
    return mappedData;
  }

  // Remove sensitive data from creation object
  // Needs much more for nested objects, other Mappers, arrays etc
  _strip(data) {
    const mappedData = this._mapMetaToObject(data);
    const safe = merge({}, mappedData);
    const sensitive = {};

    Object.keys(this.jsonMapping).forEach((sensitiveKey) => {
      set(sensitive, sensitiveKey, get(mappedData, sensitiveKey));
      unset(safe, sensitiveKey);
    });

    return {
      safe: this._mapObjectToMeta(safe),
      sensitive,
    };
  }

  // Add tokenised values back into data
  _merge(data, tokens) {
    return merge({}, data, tokens);
  }

  _mapToPrivacyFields(sensitive) {
    return Object.entries(this.jsonMapping).reduce(
      (privacyVaultFields, [apiField, privacyField]) => {
        set(privacyVaultFields, privacyField, get(sensitive, apiField));
        return privacyVaultFields;
      },
      {}
    );
  }

  _mapToApiFields(tokens) {
    return this._mapObjectToMeta(
      Object.entries(this.jsonMapping).reduce(
        (apiFields, [apiField, privacyField]) => {
          set(apiFields, apiField, get(tokens, privacyField));
          return apiFields;
        },
        {}
      )
    );
  }

  async create(ownerId, data) {
    const { safe } = this._strip(data);
    const response = await $apollo.mutate({
      mutation: this.createMutation,
      variables: safe,
    });
    const { id: resourceId } = this.creationResolver(response.data);
    return await this.update(ownerId, resourceId, data);
  }

  async update(ownerId, resourceId, data) {
    const { sensitive } = this._strip(data);
    const {
      privacyVaultTarget: { targetId },
      tokens,
    } = await $vault.save({
      privacyVaultTargetCollection: this.privacyTargetCollection,
      ownerId,
      resourceTargetId: resourceId,
      record: this._mapToPrivacyFields(sensitive),
    });

    $vault.setPrivacyVaultTarget({
      resourceTarget: {
        resourceId,
        resourceCollection: this.resourceCollection,
      },
      privacyVaultTarget: {
        targetId,
        targetCollection: this.privacyTargetCollection,
      },
    });
    const tokenisedData = this._merge(data, this._mapToApiFields(tokens));
    const response = await $apollo.mutate({
      mutation: this.updateMutation,
      variables: {
        id: resourceId,
        ...tokenisedData,
      },
    });

    return this.creationResolver(response.data);
  }

  async reveal(resourceId, data) {
    const tokens = await $vault.revealByResourceTarget({
      resourceTarget: {
        resourceId,
        resourceCollection: this.resourceCollection,
      },
    });

    return tokens ? this._merge(data, this._mapToApiFields(tokens)) : data;
  }
}

export default (ctx) => {
  $apollo = ctx.app.apolloProvider.defaultClient;
  $vault = Vue.prototype.$privacyVaultService;
  const mappers = {};
  mappers.EndOfLifeItem = new SensitiveModel({
    resourceCollection: 'END_OF_LIFE_ITEMS',
    privacyTargetCollection: 'END_OF_LIFE_ITEMS',
    creationResolver: (data) => Object.values(data)[0].endOfLifeItem,
    createMutation: CREATE_END_OF_LIFE_ITEM_MUTATION,
    updateMutation: UPDATE_END_OF_LIFE_ITEM_MUTATION,
    jsonMapping: {
      'data.account': 'account',
      'data.secret': 'secret',
    },
  });
  const sensitivePlugin = {
    install() {
      Vue.prototype.$sensitive = mappers;
    },
  };
  Vue.use(sensitivePlugin);
  return mappers;
};
