import { isModeratorOrHigher } from '@/utilities';

export default async function ({ app, redirect, store, route }) {
  if (app.$auth.loggedIn) {
    let token = app.$auth.strategy.token.get();
    token = token.split(' ')[1];
    await app.$apolloHelpers.onLogin(token);
  }
  const hasToken = !!app.$apolloHelpers.getToken();

  if (!hasToken) {
    const affiliateSlug = store.state.affiliate?.slug;
    const fullPath = route.fullPath;
    return redirect(`/${affiliateSlug || 'sw'}/log-in?redirect=${fullPath}`);
  } else if (
    isModeratorOrHigher(store.state.role) &&
    !store.state.masquerading
  ) {
    if (!route.path.includes('/log-in')) {
      return redirect('/admin/submissions');
    }
  }
}
