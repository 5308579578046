export const PRICE_DEFAULTS = {
  DEFAULT_WILL_PRICE: 160,
  DEFAULT_PARTNER_WILL_PRICE: 80,
  DEFAULT_SUBSCRIPTION_PRICE: 15,
  DEFAULT_UNLOCKING_PRICE: 80, // How much it costs for a new subscription if the old one expired.
  DEFAULT_POA_PRICE: 99,
};

export const state = () => ({
  invitedByPartner: false,
  paymentMethod: 'stripe',
});

export const getters = {
  invitedByPartner: (state) => state.invitedByPartner,
  paymentMethod: (state) => state.paymentMethod,
};

export const mutations = {
  setInvitedByPartner(state, value) {
    state.invitedByPartner = value;
  },
  setPaymentMethod(state, value) {
    state.paymentMethod = value;
  },
};

export const actions = {
  setInvitedByPartner({ commit }, value) {
    commit('setInvitedByPartner', value);
  },
  setPaymentMethod({ commit }, value) {
    commit('setPaymentMethod', value);
  },
  changePaymentMethod({ commit }, value) {
    commit('setPaymentMethod', value);
  },
  resetCheckoutState({ commit }) {
    commit('setInvitedByPartner', false);
    commit('setPaymentMethod', 'stripe');
  },
};
