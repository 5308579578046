import { trySetLocalStorage } from '@/utilities';

const FREE_WILL_WEEK = 'free-will-week';
const USE_RUACH_CHECKOUT = 'use-ruach-checkout';
const LEGAL_ADVICE_PRODUCT = 'legal-advice-product';
const FANCY_INVITES = 'fancy-invites';
const GIFTS_IN_CHECKOUT = 'gifts-in-checkout';
const PRE_PAID_CONTRACT_FLOW = 'pre-paid-contract-flow';
const EOL_FILE_UPLOAD = 'end-of-life-file-upload';
const PRIVACY_VAULT = 'privacy-vault';

export const FeatureFlagDefaults = {
  [FREE_WILL_WEEK]: false,
  [USE_RUACH_CHECKOUT]: false,
  [LEGAL_ADVICE_PRODUCT]: false,
  [FANCY_INVITES]: false,
  [GIFTS_IN_CHECKOUT]: false,
  [PRE_PAID_CONTRACT_FLOW]: false,
  [EOL_FILE_UPLOAD]: true,
  [PRIVACY_VAULT]: false,
};

// This plugin will run on client side
// and will set the cookie if not exists
export default (ctx, inject) => {
  inject('ff', {
    refreshFeatureFlags: () => {
      refreshFeatureFlags(ctx);
    },
    isFreeWillWeek: () => ctx.store.state.featureFlags[FREE_WILL_WEEK],
    isShowingLegalAdvice: () =>
      ctx.store.state.featureFlags[LEGAL_ADVICE_PRODUCT],
    useFancyInvites: () => ctx.store.state.featureFlags[FANCY_INVITES],
    isShowingGiftsInCheckout: () =>
      ctx.store.state.featureFlags[GIFTS_IN_CHECKOUT],
    prePaidContractFlowEnabled: () =>
      ctx.store.state.featureFlags[PRE_PAID_CONTRACT_FLOW],
    useEOLFileUpload: () => ctx.store.state.featureFlags[EOL_FILE_UPLOAD],
    privacyVaultEnabled: () => ctx.store.state.featureFlags[PRIVACY_VAULT],
  });

  // On the client side, right before all components begin to mount
  // Dispatch a call to set the feature flags store
  // Note that calling the API on client side may cause a "glitch"
  refreshFeatureFlags(ctx);
};

function refreshFeatureFlags(ctx) {
  if (typeof fetch === 'function') {
    fetch(`${process.env.SAFEWILL_BACKEND_API}/feature-flags`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then((result) => {
        ctx.store.commit('setFeatureFlags', result);

        // Set the local storage so that it loads faster next time
        for (const key in result) {
          trySetLocalStorage(key, result[key]);
        }
      })
      .catch(() => {
        console.error('Unable to refresh feature flags');
      });
  }
}
