import { mapActions } from 'vuex';

import ADD_PERSON_MUTATION from '@/graphql/mutations/AddPerson';
import GET_PEOPLE_QUERY from '@/graphql/queries/GetPeople';
import REMOVE_PERSON_MUTATION from '@/graphql/mutations/RemovePerson';
import UPDATE_PERSON_MUTATION from '@/graphql/mutations/UpdatePerson';

import { metaArrayToObject } from '@/utilities';
import { error } from '@/mixins/apollo';

export default {
  name: 'MixinsApolloPeople',
  apollo: {
    people: {
      query: GET_PEOPLE_QUERY,
      variables() {
        return {
          willId: this.willId,
        };
      },
      update: (data) => data.getPeople && data.getPeople.people,
      skip() {
        return !this.token || !this.willId;
      },
      error,
    },
  },
  data() {
    return {
      ADD_PERSON_MUTATION,
      REMOVE_PERSON_MUTATION,
      UPDATE_PERSON_MUTATION,
      people: [],
    };
  },
  computed: {
    getPeopleQuery() {
      return {
        query: GET_PEOPLE_QUERY,
        variables: {
          willId: this.willId,
        },
      };
    },
    minors() {
      return this.people.filter((person) => {
        const meta = metaArrayToObject(person.meta);
        return !meta.is_over_18;
      });
    },
    children() {
      return this.people.filter((person) => person.category === 'child');
    },
    partners() {
      return this.people.filter((person) => person.category === 'partner');
    },
  },
  methods: {
    ...mapActions('person', ['addPerson', 'editPerson']),
    isChild(person) {
      return person.category === 'child';
    },
    isPartner(person) {
      return person.category === 'partner';
    },
    isPerson(id) {
      return !!this.people.filter((person) => person.id === id).length;
    },
    async removePerson(person) {
      await this.$apollo
        .mutate({
          mutation: this.REMOVE_PERSON_MUTATION,
          variables: {
            id: person.id,
            willId: this.willId,
          },
          update: (store) => {
            const data = store.readQuery(this.getPeopleQuery);
            const index = data.getPeople.people.findIndex(
              (m) => m.id === person.id
            );

            if (index !== -1) {
              data.getPeople.people.splice(index, 1);

              store.writeQuery({
                ...this.getPeopleQuery,
                data,
              });
            }
          },
        })
        .catch(error);
    },
    async updatePerson(person, variables) {
      await this.$apollo
        .mutate({
          mutation: this.UPDATE_PERSON_MUTATION,
          variables: Object.assign(variables, {
            id: person.id,
          }),
        })
        .catch(error);
    },
  },
};
