export const ALLOWED_LOGIN_REDIRECTS = [
  '/',
  '/will',
  '/will/about-yourself/name',
  '/will/assets',
  '/will/estate',
  '/will/executors',
  '/will/funeral',
  '/will/gifts',
  '/will/guardians',
  '/poa',
  '/poa/about-yourself',
  '/poa/financial',
  '/poa/medical',
  '/checkout/will',
  '/checkout/poa',
  '/checkout/update-card',
  '/cremations/at-need',
  '/end-of-life',
];
export const ALLOWED_LOGIN_REDIRECTS_WITH_PARAMS = ['/checkout/generic'];

export const ALLOWED_SIGNUP_REDIRECTS = ['/will', '/poa', '/checkout/poa'];
export const ALLOWED_SIGNUP_REDIRECTS_WITH_PARAMS = ['/checkout/generic'];

const redirectSanitizerFunctionBuilder = (REDIRECTS, REDIRECTS_WITH_PARAMS) => {
  return (whereTo) => {
    if (!whereTo) {
      return '';
    }
    const sanitizedBaseUrl = REDIRECTS.find((route) =>
      route.startsWith(whereTo)
    );
    if (sanitizedBaseUrl) {
      return sanitizedBaseUrl;
    }

    const baseUrl = REDIRECTS_WITH_PARAMS.find((route) =>
      whereTo.startsWith(route)
    );
    if (baseUrl) {
      const queryParamString = whereTo.split('?')[1];
      return baseUrl + (queryParamString ? `?${queryParamString}` : '');
    }
    return '';
  };
};

export const sanitizeSignUpRedirect = (whereTo) => {
  return redirectSanitizerFunctionBuilder(
    ALLOWED_SIGNUP_REDIRECTS,
    ALLOWED_SIGNUP_REDIRECTS_WITH_PARAMS
  )(whereTo);
};

export const sanitizeLogInRedirect = (whereTo) => {
  return redirectSanitizerFunctionBuilder(
    ALLOWED_LOGIN_REDIRECTS,
    ALLOWED_LOGIN_REDIRECTS_WITH_PARAMS
  )(whereTo);
};
