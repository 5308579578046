import { queries } from '@/modules/apollo-queries/poa-meta';
import { error } from '@/mixins/apollo';

import GET_WILL_QUERY from '@/graphql/queries/GetWill';
import GET_POA_STATUS from '@/graphql/queries/GetPoaStatus';

export default {
  name: 'MixinsApolloPoa',
  apollo: {
    'About Yourself': {
      query: GET_WILL_QUERY,
      variables() {
        return {
          id: this.willId,
        };
      },
    },
    Financial: {
      ...queries.poaFinancialMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
    Medical: {
      ...queries.poaMedicalMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
    },
    poaStatus: {
      query: GET_POA_STATUS,
      fetchPolicy: 'network-only',
      update: (data) => {
        return data.poa !== null ? data.poa.status : null;
      },
      variables() {
        return { id: this.poaId };
      },
      result({ data }) {
        if (data.poa?.status === 'AWAITING_APPROVAL') {
          if (!this.poaStatusPollTimer) {
            this.$apollo.queries.poaStatus.startPolling(2000);
            this.poaStatusPollTimer = setTimeout(() => {
              this.$apollo.queries.poaStatus.stopPolling();
              this.displayErrorMessage();
              this.$nuxt.$emit('sendTrackingEvent', {
                event: '❌ POA PDF generation timeout',
                props: {
                  error:
                    'Waiting for POA PDF generation timeout after 30 seconds',
                },
              });
            }, 30000);
          }
        } else {
          clearTimeout(this.poaStatusPollTimer);
          this.poaStatusPollTimer = null;
          this.$apollo.queries.poaStatus.stopPolling();
        }
      },
      error,
    },
  },
};
