import { isModeratorOrHigher } from '@/utilities';

export default function ({ redirect, store, route, app }) {
  const hasToken = !!app.$apolloHelpers.getToken();

  if (isModeratorOrHigher(store.state.role) && !store.state.masquerading) {
    if (!route.path.includes('/log-in')) {
      return redirect('/admin/submissions');
    }
  } else if (hasToken) {
    return redirect('/');
  }
}
