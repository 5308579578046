import { isModeratorOrHigher } from '@/utilities';

export default function ({ app, redirect, store }) {
  const hasToken = !!app.$apolloHelpers.getToken();
  if (hasToken) {
    if (!isModeratorOrHigher(store.state.role)) {
      return redirect('/log-in');
    }
  } else {
    return redirect('/log-in');
  }
}
