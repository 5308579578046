import { getPathWithoutLocale } from '@/utilities/index';

export default function ({ redirect, store, route, localePath, i18n }) {
  const affiliate = store.state.affiliate;
  // we get path to check if it includes affiliate slug
  // checking fullPath may cause issues as affiliate slug can be in the query params
  const { path, fullPath } = route;
  const affiliateSlug = affiliate.slug;

  // TODO: Improve this to handle if dashboard is disabled
  if (isAllowedAffiliateRedirect(fullPath, affiliate.features)) {
    const pathWithoutLocale = getPathWithoutLocale(fullPath, i18n.localeCodes);
    // making sure the affiliate slug is not in a query param
    if (!path.includes(affiliateSlug)) {
      const redirectPath = `/${affiliateSlug}${pathWithoutLocale}`;
      return redirect(localePath(redirectPath));
    } else if (fullPath.split('/')[1] === i18n.defaultLocale) {
      return redirect(localePath(pathWithoutLocale));
    }
  } else {
    const redirectPath = `/${affiliateSlug}/`;
    return redirect(localePath(redirectPath));
  }
}

function isAllowedAffiliateRedirect(path, features) {
  if (!path) {
    return false;
  }
  if (path.includes('/will')) {
    return features.willEnabled;
  }
  if (path.includes('/power-of-attorney')) {
    return features.powerOfAttorneyEnabled;
  }
  if (path.includes('/end-of-life')) {
    return features.endOfLifeEnabled;
  }
  if (path.includes('/checkout/gifts')) {
    return features.giftEnabled;
  }
  return true;
}
