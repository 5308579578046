import GET_COUPON_QUERY from '@/graphql/queries/GetCoupon';
import UPDATE_COUPON_MUTATION from '@/graphql/mutations/UpdateCoupon';
import ARCHIVE_COUPON_MUTATION from '@/graphql/mutations/ArchiveCoupon';

import { error, user } from '@/mixins/apollo';

export default {
  name: 'MixinsApolloCoupon',
  mixins: [user],
  apollo: {
    coupon: {
      query: GET_COUPON_QUERY,
      variables() {
        return {
          id: this.couponId,
        };
      },
      update: (data) => {
        return data && data.getCoupon;
      },
      skip() {
        return !this.token || !this.couponId;
      },
      fetchPolicy: 'no-cache',
      error,
    },
  },
  data() {
    return {
      coupon: null,
    };
  },
  methods: {
    async updateCoupon(coupon) {
      await this.$apollo
        .mutate({
          mutation: UPDATE_COUPON_MUTATION,
          variables: {
            ...coupon,
          },
        })
        .catch(error);
    },
    async archiveCoupon(coupon) {
      await this.$apollo
        .mutate({
          mutation: ARCHIVE_COUPON_MUTATION,
          variables: {
            id: coupon.id,
          },
        })
        .catch(error);
    },
  },
};
