import { isBefore } from 'date-fns';
import GET_SUBSCRIPTION_QUERY from '@/graphql/queries/GetSubscription';
import CANCEL_SUBSCRIPTION_MUTATION from '@/graphql/mutations/CancelSubscription';
import UPDATE_SUBSCRIPTION_MUTATION from '@/graphql/mutations/UpdateSubscription';

import { error, user } from '@/mixins/apollo';

export default {
  name: 'MixinsApolloSubscription',
  mixins: [user],
  apollo: {
    subscription: {
      query: GET_SUBSCRIPTION_QUERY,
      variables() {
        return {
          userId: this.userId,
        };
      },
      update: (data) => data.getSubscription,
      skip() {
        return !this.token || !this.userId;
      },
      error,
    },
  },
  data() {
    return {
      subscription: null,
    };
  },
  computed: {
    formattedSubscriptionExpiryDate() {
      if (!this.subscription.expiresAt) return '';
      return this.$formatDate(this.subscription.expiresAt, 'dS mmmm yyyy');
    },
    formattedSubscriptionPaymentMethodExpiryDate() {
      if (!this.subscription.paymentMethodExpiresAt) return '';
      return this.$formatDate(
        this.subscription.paymentMethodExpiresAt,
        'dS mmmm yyyy'
      );
    },
    formattedSubscriptionCreatedAtDate() {
      if (!this.subscription.createdAt) return '';
      return this.$formatDate(this.subscription.createdAt, 'dS mmmm yyyy');
    },
    hasExpiredSubscription() {
      return !this.hasValidSubscription;
    },
    hasValidSubscription() {
      if (!this.subscription) {
        return true;
      }
      const now = new Date();
      const expiryDate = new Date(Number(this.subscription.expiresAt));
      return isBefore(now, expiryDate);
    },
    hasValidPaymentMethod() {
      if (!this.subscription) {
        return true;
      }
      const now = new Date();
      const expiryDate = new Date(
        Number(this.subscription.paymentMethodExpiresAt)
      );
      return isBefore(now, expiryDate);
    },
    showUpdateSubscriptionBanner() {
      return !this.subscription?.autoRenew;
    },
    formattedExpiryDate() {
      if (!this.subscription?.expiresAt) return '';
      return this.$formatDate(this.subscription.expiresAt, 'dS mmmm yyyy');
    },
  },
  methods: {
    async refetchSubscription() {
      await this.$apollo.queries.subscription.refetch();
    },
    async cancelSubscription() {
      const response = await this.$apollo
        .mutate({
          mutation: CANCEL_SUBSCRIPTION_MUTATION,
          variables: {
            userId: this.userId,
          },
        })
        .then((res) => {
          this.$nuxt.$emit('sendTrackingEvent', {
            event: 'Pause Subscription',
          });
          this.$apollo.queries.subscription.refetch();

          return res;
        });

      return response;
    },
    async updateSubscription(cardToken) {
      const response = await this.$apollo.mutate({
        mutation: UPDATE_SUBSCRIPTION_MUTATION,
        variables: {
          userId: this.userId,
          cardToken,
        },
      });

      return response;
    },
  },
};
