import GET_INVITES_QUERY from '@/graphql/queries/GetInvites';

import { error } from '@/mixins/apollo';

export default {
  name: 'MixinsApolloInvites',
  apollo: {
    invites: {
      query: GET_INVITES_QUERY,
      variables() {
        return {
          userId: this.userId,
        };
      },
      update: (data) => data.getInvites && data.getInvites.invites,
      skip() {
        return !this.token;
      },
      error,
    },
  },
  computed: {
    getInvitesQuery() {
      return {
        query: GET_INVITES_QUERY,
        variables: {
          userId: this.userId,
        },
        skip() {
          return !this.token;
        },
      };
    },
    invitedBy() {
      return this.will?.invite?.user?.email ?? 'N/A';
    },
    completeInvites() {
      return this.will?.invites?.filter(
        (invite) => invite.purchased && invite.type !== 'PUBLIC'
      ).length;
    },
  },
  data() {
    return {
      invites: [],
    };
  },
};
