import { gql } from 'graphql-tag';
import { metaArrayToObject } from '@/utilities';
import { error } from '@/mixins/apollo';

const GET_POA_META = gql`
  query GetPoaMeta($poaId: ID) {
    poa(id: $poaId) {
      id
      meta {
        id
        key
        value
        category
      }
    }
  }
`;

const UPDATE_POA_META = gql`
  mutation UpdatePoaMeta($poaId: ID, $meta: [PoaMetaInput!]!) {
    updatePoa(input: { id: $poaId, meta: $meta }) {
      id
      status
      meta {
        id
        key
        value
        category
      }
    }
  }
`;

function getPoaMeta(category) {
  return {
    query: GET_POA_META,
    update(data) {
      if (data.poa) {
        const metaForCategory = data.poa.meta.filter(
          (metaItem) => metaItem.category === category
        );
        return metaArrayToObject(metaForCategory);
      }
      return {};
    },
    error,
  };
}

const poaMedicalMeta = getPoaMeta('MEDICAL');
const poaFinancialMeta = getPoaMeta('FINANCIAL');

function updatePoaMeta(poaId, { key, value, category }) {
  return updatePoaMetas(poaId, [{ key, value, category }]);
}

function updatePoaMetas(poaId, metaArray) {
  return {
    mutation: UPDATE_POA_META,
    variables: {
      poaId,
      meta: metaArray,
    },
    error,
  };
}

const queries = {
  poaFinancialMeta,
  poaMedicalMeta,
};

const mutations = {
  updatePoaMeta,
  updatePoaMetas,
};

export { queries, mutations };
