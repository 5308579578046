import CHECK_POA_PURCHASED_QUERY from '@/graphql/queries/CheckPoaPurchased';
import GET_PURCHASED_PRODUCTS from '@/graphql/queries/GetPurchasedProducts';

export default {
  name: 'MixinsApolloPurchasedProducts',
  apollo: {
    isPoaPurchased: {
      query: CHECK_POA_PURCHASED_QUERY,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          userId: this.userId,
          poaId: this.poaId,
        };
      },
      skip() {
        return !this.userId;
      },
      update(data) {
        const purchasedProducts = data.getPurchasedProducts?.products || [];
        const poaStatus = data.poa !== null ? data.poa.status : null;
        return poaStatus !== null || purchasedProducts.includes('POA');
      },
    },
    isWillPurchased: {
      query: GET_PURCHASED_PRODUCTS,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          userId: this.userId,
        };
      },
      skip() {
        return !this.userId;
      },
      update(data) {
        const purchasedProducts = data.getPurchasedProducts?.products || [];
        return (
          purchasedProducts.includes('WILL') ||
          purchasedProducts.includes('PARTNER_WILL')
        );
      },
    },
    isLegalAdvicePurchased: {
      query: GET_PURCHASED_PRODUCTS,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          userId: this.userId,
        };
      },
      skip() {
        return !this.userId;
      },
      update(data) {
        const purchasedProducts = data.getPurchasedProducts?.products || [];
        return purchasedProducts.includes('LEGAL_ADVICE');
      },
    },
  },
  data() {
    return {
      isPoaPurchased: null,
      isWillPurchased: null,
    };
  },
  computed: {
    isWillOrPoaPurchased() {
      return this.isPoaPurchased || this.isWillPurchased;
    },
  },
};
